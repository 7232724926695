import { createSelector } from '@reduxjs/toolkit'

import type { AdminsState } from './slices/adminsSlice'
import type { ContractorsState } from './slices/contractorsSlice'
import type { NotificationState } from './slices/notificationSlice'
import type { ProfileState } from './slices/profileSlice'

const rootProfileSelector = (state: { profile: ProfileState }) => state.profile
const rootNotificationSelector = (state: { notification: NotificationState }) => state.notification
const rootContractorsSelector = (state: { contractors: ContractorsState }) => state.contractors
const rootAdminsSelector = (state: { admins: AdminsState }) => state.admins

export const profileSelector = createSelector(rootProfileSelector, ({ profileData }) => profileData)
export const isLoggedInSelector = createSelector(rootProfileSelector, ({ profileData }) => !!profileData)

export const notificationsSelector = createSelector(rootNotificationSelector, ({ notifications }) => notifications)

export const contractorsSelector = createSelector(rootContractorsSelector, ({ contractors }) => contractors)

export const adminsSelector = createSelector(rootAdminsSelector, ({ admins }) => admins)
