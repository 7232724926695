import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import { gapi } from 'gapi-script'
import ReactDOM from 'react-dom/client'

import { theme } from 'components/theme'
import { store } from 'store'

import { getAppConfig } from 'config'
import App from './App'
import reportWebVitals from './reportWebVitals'

const { googleClientId, googleApiKey, scope } = getAppConfig()

const initClient = () => {
  gapi.client.init({
    clientId: googleClientId,
    apiKey: googleApiKey,
    scope,
    discoveryDocs: [
      'https://sheets.googleapis.com/$discovery/rest?version=v4',
      'https://www.googleapis.com/discovery/v1/apis/people/v1/rest'
    ]
  })

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  )
}

gapi.load('client', initClient)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
