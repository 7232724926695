import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { getNextScheduleDate } from 'api/schedulers'
import { isLoggedInSelector } from 'store/selectors'

import { ROUTES } from './constants'

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const [hasPerm, setHasPerm] = useState(true)

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    getNextScheduleDate()
      .then(() => {
        setHasPerm(true)
      })
      .catch(() => {
        setHasPerm(false)
      })
  }, [isLoggedIn])

  if (!isLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} />
  }

  if (!hasPerm) {
    return (
      <div
        style={{
          minHeight: '100vh',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '25px',
          textAlign: 'center',
          padding: 20
        }}
      >
        Sorry, you do not have enough permissions for the Jira Notification Automation application. Please contact
        it.support@agileengine.com if you have any questions.
      </div>
    )
  }

  return <>{children}</>
}
