import { useState } from 'react'
import type { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { useGoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'

import { CssBaseline, styled } from '@mui/material'

import { getAppConfig } from 'config'
import { RootRoutes } from 'router/RootRoutes'
import { loginAction } from 'store/slices/profileSlice'

const AppWrap = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '100vw',
  backgroundColor: theme.palette.background.default,
  position: 'relative'
}))

const { googleClientId, scope } = getAppConfig()

function App() {
  const [tryingToLogin, setTryingToLogin] = useState(true)

  const dispatch = useDispatch()

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const resp = res as GoogleLoginResponse

    // exclude GoogleLoginResponseOffline
    if (resp.profileObj) {
      dispatch(
        loginAction({
          profile: resp.profileObj,
          token: resp.tokenObj
        })
      )
    }

    setTryingToLogin(false)
  }

  const onFailure = (err: unknown) => {
    console.log('APP', err)
    setTryingToLogin(false)
  }

  const onAutoLoadFinished = () => {
    // no stored login
    setTryingToLogin(false)
  }

  useGoogleLogin({
    clientId: googleClientId,
    onFailure,
    onSuccess,
    isSignedIn: true,
    accessType: 'offline',
    scope,
    onAutoLoadFinished
  })

  if (tryingToLogin) {
    return null
  }

  return (
    <AppWrap>
      <CssBaseline />
      <RootRoutes />
    </AppWrap>
  )
}

export default App
