export const getAppConfig = () => {
  const conf = {
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '875656983382-2mmc916kocg7oa395m666ab54lrase2o.apps.googleusercontent.com',
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? 'AIzaSyDL3XP7FsxLTZ3PsVP52vtFxcJnu-QUB8c',
    apiHost: process.env.REACT_APP_API_HOST ?? 'http://localhost:8080',
    scope: 'profile email https://www.googleapis.com/auth/directory.readonly'
  }

  return conf
}
