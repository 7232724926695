import axios from 'axios'
import { gapi } from 'gapi-script'

import { getAppConfig } from 'config'

import { MOCK } from './contractorsMock'
import type { Contractor } from './types'

const CONTRACTORS = '/contractors'

axios.interceptors.request.use(function (config) {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers.Authorization = `Bearer ${gapi.auth.getToken().access_token}`
  return config
})

export const getContractors = async () => {
  try {
    const resp = await axios.get<Contractor[]>(`${getAppConfig().apiHost}${CONTRACTORS}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const getHistoricalContractors = async (start: string, end: string) => {
  try {
    const resp = await axios.get<Contractor[]>(`${getAppConfig().apiHost}${CONTRACTORS}`, {
      params: {
        initialDate: start,
        finalDate: end
      }
    })
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const fetchContractors = async () => {
  try {
    const resp = await axios.post<Contractor[]>(`${getAppConfig().apiHost}${CONTRACTORS}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const getContractorsMock = async (): Promise<Contractor[]> => {
  return new Promise(resolve => setTimeout(() => resolve([...MOCK]), 1500))
}
