import { Navigate, Route, Routes } from 'react-router-dom'

import { PageAdmins } from 'components/pages/PageAdmins'
import { PageContractorsList } from 'components/pages/PageContractorsList'
import { PageLogIn } from 'components/pages/PageLogIn'
import { PageNotificationManagement } from 'components/pages/PageNotificationManagement'

import { AnonymousOnly } from './AnonymousOnly'
import { ROUTES } from './constants'
import { NoContent } from './NoContent'
import { RequireAuth } from './RequireAuth'

export const RootRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          <AnonymousOnly>
            <PageLogIn />
          </AnonymousOnly>
        }
      />

      <Route
        path={ROUTES.ADMINS}
        element={
          <RequireAuth>
            <PageAdmins />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.CONTRACTORS_LIST}
        element={
          <RequireAuth>
            <PageContractorsList />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.NOTIFICATION_MANAGEMENT}
        element={
          <RequireAuth>
            <PageNotificationManagement />
          </RequireAuth>
        }
      />

      {/* anonymous will go to login, others to users  */}
      <Route
        path={ROUTES.HOME}
        element={
          <RequireAuth>
            <Navigate to={ROUTES.CONTRACTORS_LIST} />
          </RequireAuth>
        }
      />
      <Route path="*" element={<NoContent />} />
    </Routes>
  )
}
